import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Api from "../network/api";
import React, {Component} from "react";
import { Badge, FormControl, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import AgreementPanelConnector from "../store/connectors/dashboard/AgreementPanelConnector";
import InvoicePanelConnector from "../store/connectors/dashboard/InvoicePanelConnector";
import PrivateLayout from './layouts/PrivateLayout'
import ConsumptionPanelConnector from "../store/connectors/dashboard/ConsumptionPanelConnector";
import DashboardIcon from '../assets/images/icons/dashboardPage/dashboardIcon.png';
import Image from 'react-bootstrap/Image';
import ContactSection from "../components/common/contact/ContactSection";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReactLoading from "react-loading";
import "bootstrap/dist/css/bootstrap.css";
import * as houseLoading from "../houseLoading.json";
import * as doneData from "../doneLoading.json";
import DashboardSection from '../components/dashboard/DashboardSection';
import {AgreementsIcon, SearchInvoicesIcon, InvoicesIcon} from '../assets/images/icons/dashboardPage/agreementPanelIcon.png';
import ActionModal from '../modals/action';
import InvoicePanelModalConnector from "../store/connectors/modal/InvoicePanelModalConnector";



const defaultOptions = {
loop: true,
autoplay: false,
animationData: houseLoading.default,
rendererSettings: {
preserveAspectRatio: "xMidYMid slice"
}
}

const defaultOptions2 = {
   loop: false,
   autoplay: false,
   animationData: doneData.default,
   rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
   }
};

export default class DashboardPage extends Component {

      constructor(props){
         super(props)
         this.state = {
            done: undefined,
            loading: undefined
         }
      }

    componentDidMount() {

      setTimeout(() => {
            Api
            .get('/agreements')
            .then(({ data }) => {
                this.props.setUserAgreements(data)
            })
            .then(json => {
               this.setState({ loading: true });
               setTimeout(() => {
                  this.setState({ done: true });
               }, 1000);
            });
         }, 1200);

        Api
          .get('/deliveries')
          .then(({ data }) => {
            this.props.setUserDeliveries(data)
          })

        Api
          .get('/invoices')
          .then(({ data }) => {
            this.props.setUserInvoices(data)
          })
    }

  render() {
    return (
      <PrivateLayout className="page-dashboard">
        <InvoicePanelModalConnector/>
        <Row>
          <Col>
            <header className="page-content-header">
              <Image src={DashboardIcon} />
              <span className="page-title">Dit overblik</span>
            </header>
          </Col>
        </Row>
        <Row>
          <Col>
            {!this.state.done ? (
              <FadeIn>
                  <DashboardSection
                    className="agreements-panel"
                    headerIcon={AgreementsIcon}
                    headerText="Dine aftaler"
                  >
                  {!this.state.loading ? (
                    <Lottie options={defaultOptions} height={120} width={120} />
                  ) : (
                    <Lottie options={defaultOptions2} height={120} width={120} />
                  )}
                  </DashboardSection>
              </FadeIn>
            ) : (
              <FadeIn>
              <AgreementPanelConnector />
              </FadeIn>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!this.state.done ? (
              <FadeIn>
                <DashboardSection
                  className="invoices-panel"
                  headerIcon={InvoicesIcon}
                  headerText="Dine senester regninger"
                  headerToolbar={(
                    <span className="invoice-search-field d-none d-lg-flex">
                      <Image src={SearchInvoicesIcon} />
                      <span htmlFor="invoices-filter-input">Søg faktura</span>
                      <FormControl
                        id="invoices-filter-input"
                        ref={this.filter}
                        placeholder="Indtast fakturanummer"
                        aria-label="Indtast fakturanummer"
                      />
                      <Button onClick={() => this.setInvoiceFilter()}>Søg</Button>
                    </span>
                  )}
                >
                  {!this.state.loading ? (
                    <Lottie options={defaultOptions} height={120} width={120} />
                  ) : (
                    <Lottie options={defaultOptions2} height={120} width={120} />
                  )}
                  </DashboardSection>
              </FadeIn>
            ) : (
              <FadeIn>
              <InvoicePanelConnector />
              </FadeIn>
            )}
          </Col>
        </Row>
        <ContactSection />
      </PrivateLayout>

    );
  }
}


// <PrivateLayout className="page-dashboard">
//   <Row>
//     <Col>
//       <header className="page-content-header">
//         <Image src={DashboardIcon} />
//         <span className="page-title">Dit overblik</span>
//       </header>
//     </Col>
//   </Row>
//   <Row>
//     <Col>
//       <AgreementPanelConnector />
//     </Col>
//   </Row>
//   <Row>
//     <Col>
//       <InvoicePanelConnector />
//     </Col>
//   </Row>
//   <ContactSection />
// </PrivateLayout>




// <Row>
//   <Col>
//     <ConsumptionPanelConnector />
//   </Col>
// </Row>
