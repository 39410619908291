import axios from 'axios';
import Cookies from 'js-cookie';
import { cleanStorage as cleanAuthStorage } from '../store/persisters/AuthPersister';

const Api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  withCredentials: true,
});

let token = null;

Api.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

function clearRememberMeCookies() {
  Object.keys(Cookies.get()).filter(cookieName => cookieName.startsWith('remember_'))
    .forEach(cookieName => Cookies.remove(cookieName));
}

Api.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      token = null;
      cleanAuthStorage();
      clearRememberMeCookies();
      window.location = '/login';
    }

    return Promise.reject(error);
  },
);

export function setToken(newToken) {
  token = newToken;
}

export function resetToken() {
  token = null;
}

export default Api;
