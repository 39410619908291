import { ActionTypes } from "../Types";

const initialState = [
]

const deliveries = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_DELIVERIES:
      return action.deliveries

    case ActionTypes.SET_DELIVERY_CONSUMPTION:
      return state.map((delivery, index) => {
        if(delivery.id === action.deliveryId) {
          return {
            ...delivery,
            consumption: action.consumption
          }
        }

        return delivery;
      });

    default:
      return state
  }
}

export default deliveries
