export const ActionTypes = {
  // Authentication
  SET_AUTHENTICATED_USER: 'set_authenticated_user',
  CLEAR_AUTHENTICATED_USER: 'clear_authenticated_user',
  SET_ACCESS_TOKEN: 'set_access_token',
  CLEAR_ACCESS_TOKEN: 'clear_access_token',

  // Agreements
  SET_USER_AGREEMENTS: 'set_user_agreements',
  UPDATE_AGREEMENT: 'update_agreement',

  // Deliveries
  SET_USER_DELIVERIES: 'set_user_deliveries',
  SET_DELIVERY_CONSUMPTION: 'set_delivery_consumption',

  // Invoices
  SET_USER_INVOICES: 'set_user_invoices',
  UPDATE_INVOICE: 'update_invoice',
};
