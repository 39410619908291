import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Api from '../network/api';

class PaidInvoicePage extends Component {
  componentDidMount() {
    const { invoiceNo, updateInvoice } = this.props;

    Api.post(`/invoices/${invoiceNo}/paid`).then((data) => {
      updateInvoice(data);
    });
  }

  render() {
    const { agreementId } = this.props;
    return (
      <>
        <Redirect to={`/agreements/${agreementId}`} />
      </>
    );
  }
}

PaidInvoicePage.propTypes = {
  invoiceNo: PropTypes.string.isRequired,
  agreementId: PropTypes.string.isRequired,
  updateInvoice: PropTypes.func.isRequired,
};

export default PaidInvoicePage;
