import React from 'react';
import Container from 'react-bootstrap/Container';
import PublicTopNavBar from '../../components/scaffolding/public/PublicTopNavBar';

class PublicLayout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="theme-public">
        <PublicTopNavBar />
        <Container>
          {children}
        </Container>
      </div>
    );
  }
}

export default PublicLayout;
