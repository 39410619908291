import React from 'react';
import Image from 'react-bootstrap/Image';
import PropTypes from 'prop-types';
import profileIcon from '../../assets/images/icons/topNavBar/profileInverted.png';

class UserInfo extends React.Component {

  convertCustomer_no(n, width, z) {
    z = z || '0';
    n = n + '';
      return n.length >= width ? "B" + n : "B" + new Array(width - n.length + 1).join(z) + n;
  }

  render() {
    const { userName, customerNumber } = this.props;

    return (
      <div className="navbar-item-userinfo d-none d-lg-flex">
        <Image width="27px" height="31px" src={profileIcon} />
        <span className="d-flex flex-column">
          <span className="userinfo-name">{userName}</span>
          <span className="userinfo-customerno">
            Kundenr. {this.convertCustomer_no(customerNumber, 7)}
          </span>
        </span>
      </div>
    );
  }
}

UserInfo.propTypes = {
  userName: PropTypes.string,
  customerNumber: PropTypes.string,
};

UserInfo.defaultProps = {
  userName: '',
  customerNumber: '',
};

export default UserInfo;
