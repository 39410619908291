import { connect } from 'react-redux';
import InvoicePanel from '../../../components/dashboard/InvoicePanel';

const mapStateToProps = state => ({
  invoices: state.invoices,
})
const mapDispatchToProps = () => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoicePanel);
