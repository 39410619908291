import { connect } from 'react-redux';
import { updateAgreement } from '../../actions/agreementActions';
import SubscriptionSummary from '../../../components/agreements/SubscriptionSummary';

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  updateAgreement: updatedAgreement => dispatch(updateAgreement(updatedAgreement)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionSummary);
