import {connect} from "react-redux";
import SideNav from "../../../components/scaffolding/SideNav";
import {withRouter} from "react-router-dom";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNav))
