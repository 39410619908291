import {connect} from "react-redux";
import { withRouter } from 'react-router';
import AgreementsAccordion from "../../../components/agreements/AgreementsAccordion";

const mapStateToProps = (state, ownProps) => ({
  agreements: state.agreements,
  selectedAgreement: ownProps.selectedAgreement,
});

const mapDispatchToProps = () => ({
});

const AgreementsAccordionConnector = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementsAccordion))

export default AgreementsAccordionConnector;
