import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Api from '../network/api';

class DashboardPaidInvoicePage extends Component {
  componentDidMount() {
    const { invoiceNumber, updateInvoice } = this.props;

    Api.post(`/invoices/${invoiceNumber}/paid`).then((data) => {
      updateInvoice(data);
    });
  }

  render() {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }
}

DashboardPaidInvoicePage.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  updateInvoice: PropTypes.func.isRequired,
};

export default DashboardPaidInvoicePage;
