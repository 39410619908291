import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {loadFromStorage as loadAuthDataFromStorage} from "./store/persisters/AuthPersister";
import {setToken as setApiToken} from "./network/api";

const authData = loadAuthDataFromStorage()
if (authData && authData.token) {
  setApiToken(authData.token)
}

ReactDOM.render(<App />, document.getElementById('appContainer'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
