import { ActionTypes} from "../Types";

export const setAuthenticatedUser = user => ({
  type: ActionTypes.SET_AUTHENTICATED_USER,
  user: user
})

export const clearAuthenticatedUser = () => ({
  type: ActionTypes.CLEAR_AUTHENTICATED_USER
})

export const setAccessToken = token => ({
  type: ActionTypes.SET_ACCESS_TOKEN,
  token: token
})

export const clearAccessToken = () => ({
  type: ActionTypes.CLEAR_ACCESS_TOKEN
})
