import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import activeIndicator from '../../assets/images/icons/sideNav/activeItemIndicator.png';

class SideNavItem extends React.Component {
  render() {
    const {
      icon, text, url, active,
    } = this.props;
    const itemClasses = classNames({ active });

    return (
      <>
        <Nav.Item className={itemClasses}>
          <Image src={icon} />
          <NavLink to={url}>{text}</NavLink>
          <Image className="active-item-indicator" src={activeIndicator} />
        </Nav.Item>
      </>
    );
  }
}

SideNavItem.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

SideNavItem.defaultProps = {
  active: false,
};
export default SideNavItem;
