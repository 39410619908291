import React from 'react';
import Image from 'react-bootstrap/Image';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ContactAd extends React.Component {
  render() {
    const {
      className, description, icon, title, phone,
    } = this.props;

    return (
      <div className={classNames('panel-contact', className)}>
        <Image src={icon} />
        <span className="title">{ title }</span>
        <span className="phone">{phone}</span>
        <span className="description">
          {description.split('\n').map(line => (
            <>
              {line}
              <br />
            </>
          ))}
        </span>
      </div>
    );
  }
}

ContactAd.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ContactAd.defaultProps = {
  className: '',
};

export default ContactAd;
