export function getConsumptionFromDelivery(delivery) {
  return delivery && delivery.consumption && delivery.consumption.last_months
    ? delivery.consumption.last_months
    : [];
}

export function getConsumptionChartLabelsFromDelivery(delivery) {
  return getConsumptionFromDelivery(delivery).map(consumption => consumption.month);
}

export function getEstimatedConsumptionDataFromDelivery(delivery) {
  return getConsumptionFromDelivery(delivery).map(consumption => consumption.estimated);
}

export function getRealConsumptionDataFromDelivery(delivery) {
  return getConsumptionFromDelivery(delivery).map(consumption => consumption.real);
}
