import { ActionTypes } from '../Types';

export const setUserInvoices = invoices => ({
  type: ActionTypes.SET_USER_INVOICES,
  invoices: invoices,
});

export const updateInvoice = updatedInvoice => ({
  type: ActionTypes.UPDATE_INVOICE,
  updatedInvoice: updatedInvoice,
});
