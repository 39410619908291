import { ActionTypes } from '../Types';
import { loadFromStorage, saveToStorage } from '../persisters/AuthPersister';
import { setToken as setApiToken, resetToken as resetApiToken } from '../../network/api';

const initialState = loadFromStorage() || {
  token: null,
  /** @see {@link /docs/TYPES.md#user} for type definition */
  user: null,
};

const auth = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case ActionTypes.SET_AUTHENTICATED_USER:
      newState = {
        ...state,
        user: action.user,
      };
      saveToStorage(newState);
      return newState;

    case ActionTypes.CLEAR_AUTHENTICATED_USER:
      newState = {
        ...state,
        user: null,
      };
      saveToStorage(newState);
      return newState;

    case ActionTypes.SET_ACCESS_TOKEN:
      newState = {
        ...state,
        token: action.token,
      };
      setApiToken(action.token);
      saveToStorage(newState);
      return newState;

    case ActionTypes.CLEAR_ACCESS_TOKEN:
      newState = {
        ...state,
        token: null,
      };
      resetApiToken();
      saveToStorage(newState);
      return newState;

    default:
      return state;
  }
};

export default auth;
