import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import InvoicePanelConnector from "../store/connectors/modal/InvoicePanelModalConnector";
import Lottie from "react-lottie";
import * as quastionMark from "../questionMark.json";

const defaultOptions = {
   loop: true,
   autoplay: true,
   animationData: quastionMark.default,
   rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
   }
};

const mystyle = {
    textAlign: 'center',
    alignSelf: 'center'
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title centered id="contained-modal-title-vcenter">
            <Row><Lottie options={defaultOptions} height={120} width={120} />
            <h1 style={mystyle}>Hov, du har en ubetalt regning, lad os klare den med det samme!</h1></Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvoicePanelConnector/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ActionModal() {
    const [modalShow, setModalShow] = React.useState(false);


      useEffect(() => {

      });

    return (
      <ButtonToolbar>
        <Button variant="primary" style={{color: '#54b8f3'}} onClick={() => setModalShow(true)}>
          Launch vertically centered modal
        </Button>

        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </ButtonToolbar>
    );
  }



export default ActionModal;
