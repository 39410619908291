import React from 'react';
import {
  Badge, Button, Image, Table,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Paginator from '../common/Paginator';
import { formatIsoDate } from '../../helpers/formats';
import OnlinePaymentModal from '../common/payments/OnlinePaymentModal';
import Api from '../../network/api';
import download from '../../helpers/files';
import PdfIcon from '../../assets/images/icons/common/pdfIcon.png';
import MoneyCell from '../common/tables/MoneyCell';
import { getStatusDanishTranslation } from '../../helpers/translations';
import InvoiceStatusType from '../../types/InvoiceStatusType';

class InvoicesTable extends React.Component {
  constructor(props) {
    super(props);

    this.onlinePaymentModalRef = React.createRef();

    this.state = {
      currentPage: 1,
      pageLimit: 3,
      firstVisibleRecord: 0,
      lastVisibleRecord: 0,
      selectedInvoice: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      lastVisibleRecord: Math.min(
        nextProps.invoices.length,
        (prevState.currentPage * prevState.pageLimit) - 1,
      ),
    };
  }

  static getDescription(invoice) {
    return `Faktura ${invoice.invoice_no}`;
  }

  static invoiceHasVerifiedPayment(invoice) {
    return invoice.balance === 0;
  }

  displayCurrentPage = (paginationData) => {
    const { invoices } = this.props;

    this.setState({
      currentPage: paginationData.currentPage,
      firstVisibleRecord: (paginationData.currentPage - 1) * paginationData.pageLimit,
      lastVisibleRecord: Math.min(
        invoices.length,
        paginationData.currentPage * paginationData.pageLimit,
      ),
    });
  }

  openOnlinePaymentModal = (invoice) => {
    this.setState(
      { selectedInvoice: invoice },
      () => { this.onlinePaymentModalRef.current.open(); },
    );
  }

  handleInvoiceDownload = (invoice) => {
    Api.get(`/invoices/${invoice.invoice_no}`, { responseType: 'blob' })
      .then((response) => {
        download(response.data, `${invoice.invoice_no}.pdf`);
      })
      .catch(() => {
        toast.error('Der opstod en fejl under download af fakturaen');
      });
  }

  canPayInvoice(invoice) {
    return invoice.balance > 0 && invoice.status !== InvoiceStatusType.collected;
  }

  getStatusColor(status) {
    switch (status) {
      case 'collected':
        return 'success';
      case 'credited':
        return 'info';
      case 'unpaid':
        return 'danger';
      case 'paid':
        return 'success';
      case 'unknown':
      default:
        return 'secondary';
    }
  }

  renderStatus(invoice) {
    return (
      <Badge variant={invoice.price > 0 ? this.getStatusColor(invoice.status) : 'info'}>
        {invoice.price > 0 ? getStatusDanishTranslation(invoice.status) : 'Kreditnota'}
      </Badge>
    );
  }

  renderAction(invoice) {
    return this.canPayInvoice(invoice) ? (
      <Button
        variant="link"
        style={{ color: "#54b8f3" }}
        onClick={() => {
          this.openOnlinePaymentModal(invoice);
        }}
      >
        Betal nu
      </Button>
    ) : (
      ""
    );
  }

  render() {
    const { invoices, agreementId } = this.props;
    const {
      firstVisibleRecord, lastVisibleRecord, currentPage, pageLimit, selectedInvoice,
    } = this.state;
    const rows = [];

    invoices.forEach((invoice, index) => {
      if ((index >= firstVisibleRecord) && (index <= lastVisibleRecord)) {
        rows.push(
          <tr key={invoice.id}>
            <td>{formatIsoDate(invoice.invoice_date)}</td>
            <td>{InvoicesTable.getDescription(invoice)}</td>
            <td>
              <Button
                variant="link"
                style={{ color: "#54b8f3" }}
                onClick={() => this.handleInvoiceDownload(invoice)}
              >
                Se faktura
                <Image className="pdfInvoiceIcon" src={PdfIcon} />
              </Button>
            </td>
            <td>{this.renderStatus(invoice)}</td>
            <td>{this.renderAction(invoice)}</td>
            <MoneyCell currency="DKK" amount={invoice.price_incl_vat} />
          </tr>
        );
      }
    });

    return (
      <>
        <Table size="sm" borderless className="table-invoices">
          <thead>
            <tr>
              <th>Dato</th>
              <th>Beskrivelse</th>
              <th>Faktura</th>
              <th>Status</th>
              <th>Handling</th>
              <th>Pris inkl. moms</th>
            </tr>
          </thead>
          <tbody>
            {
              invoices.length > 0
                ? rows
                : (<tr><td colSpan="6">Der er ingen fakturaer</td></tr>)
            }
          </tbody>
        </Table>
        <Paginator
          currentPage={currentPage}
          pageLimit={pageLimit}
          totalRecords={invoices.length}
          onPageChanged={this.displayCurrentPage}
        />
        <OnlinePaymentModal
          ref={this.onlinePaymentModalRef}
          amount={selectedInvoice.price_incl_vat}
          orderId={selectedInvoice.entry_no}
          callbackUrl={process.env.REACT_APP_EPAY_CALLBACK_URL}
          merchantNumber={process.env.REACT_APP_EPAY_MERCHANT_NUMBER}
          acceptUrl={
            process.env.REACT_APP_EPAY_PAYMENT_ACCEPT_HOST
            + `/agreements/${agreementId}/invoices/${selectedInvoice.invoice_no}/paid`
          }
        />
      </>
    );
  }
}

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  agreementId: PropTypes.string.isRequired,
};

export default InvoicesTable;
