import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import ConsumptionGraph from '../../../components/consumpion/ConsumptionGraph';
import { setDeliveryConsumption } from '../../actions/deliveryActions';

const mapStateToProps = state => ({
  deliveries: state.deliveries.filter(
    delivery => delivery.end_date === null
        || DateTime.fromISO(delivery.end_date) > DateTime.local(),
  ),
  agreements: state.agreements,
});

const mapDispatchToProps = dispatch => ({
  setDeliveryConsumption: (deliveryId, consumption) => {
    dispatch(setDeliveryConsumption(deliveryId, consumption));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsumptionGraph);
