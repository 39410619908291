import {connect} from "react-redux";
import UserInfo from "../../../components/scaffolding/UserInfo";

const mapStateToProps = state => ({
  userName: state.auth.user && state.auth.user.name ? state.auth.user.name : 'Unknown User',
  customerNumber: state.auth.user && state.auth.user.customer_no ? state.auth.user.customer_no : 'Unknown No',
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfo)
