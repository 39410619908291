import React, { useRef } from 'react';
import { render } from 'react-dom';
import Accordion from 'react-bootstrap/Accordion';
import PropTypes from 'prop-types';
import AgreementsAccordionItemConnector from '../../store/connectors/agreements/AgreementsAccordionItemConnector';

class AgreementsAccordion extends React.Component {

  constructor(props) {
    super(props)
}

//const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)


  navigateTo = (itemKey) => {
    const { history } = this.props;

    if (itemKey === null) {
      history.push('/agreements');
    } else {
      history.push(`/agreements/${itemKey}`);
    }
  };

  // const AgreementsAccordionItemConnector = () => {
  //   const myRef = useRef(null);
  //
  //   useMountEffect(scrollToRef(myRef)) // Scroll on mount
  //
  //   return (
  //     <div ref={selectedAgreement || agreement.id == selectedAgreement.id ? this.myRef() : null}>
  //
  //     </div>)
  //
  // }


  render() {
    const { selectedAgreement, agreements } = this.props;
    return (

      <Accordion
        className="agreements-accordion"
        activeKey={selectedAgreement ? selectedAgreement.id : ''}
        onSelect={this.navigateTo}
      >
        {
          agreements.map(
            agreement => (
              <AgreementsAccordionItemConnector
                key={agreement.id}
                item={agreement}
                folded={!selectedAgreement || agreement.id !== selectedAgreement.id}
              />
            ),
          )
        }
      </Accordion>
    );
  }
}

AgreementsAccordion.propTypes = {
  history: PropTypes.instanceOf(History).isRequired,
  agreements: PropTypes.arrayOf(PropTypes.object),
  selectedAgreement: PropTypes.object,
};

export default AgreementsAccordion;
