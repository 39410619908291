import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { clearAccessToken, clearAuthenticatedUser } from '../../actions/authActions';
import TopNavBar from '../../../components/scaffolding/TopNavBar';

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  clearAuthenticatedUser: () => dispatch(clearAuthenticatedUser()),
  clearAccessToken: () => dispatch(clearAccessToken()),
});

export default withCookies(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopNavBar)));
