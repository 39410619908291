import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import PublicLayout from './layouts/PublicLayout';
import GeneratePasswordFormConnector from '../store/connectors/auth/GeneratePasswordFormConnector';

class GenerateNewPasswordPage extends Component {
  render() {
    return (
      <PublicLayout>
        <div className="page-generate-password-content">
          <Row className="justify-content-center">
            <header>Glemt kodeord</header>
          </Row>
          <Row className="justify-content-center">
            <GeneratePasswordFormConnector />
          </Row>
        </div>
      </PublicLayout>
    );
  }
}

export default GenerateNewPasswordPage;
