import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../../helpers/formats';

class MoneyCell extends Component {
  render() {
    const { currency, amount } = this.props;

    return (
      <td className="money">
        <span className="currency">{currency}</span>
        <span className="amount">{formatMoney(amount)}</span>
      </td>
    );
  }
}

MoneyCell.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.number,
};

MoneyCell.defaultProps = {
  currency: 'DKK',
  amount: 0,
};

export default MoneyCell;
