import React from 'react';
import Nav from 'react-bootstrap/Nav';
import PropTypes from 'prop-types';
import AgreementsIcon from '../../assets/images/icons/sideNav/agreement.png';
import ConsumptionIcon from '../../assets/images/icons/sideNav/consumption.png';
import DashboardIcon from '../../assets/images/icons/sideNav/dashboard.png';
import ProfileIcon from '../../assets/images/icons/sideNav/profile.png';
import SideNavItem from './SideNavItem';

export default class SideNav extends React.Component {
  render() {
    const { location } = this.props;
    return (
        <Nav defaultActiveKey="home" className="nav-side flex-column">
          <SideNavItem icon={DashboardIcon} text="Dit overblik" url="/" active={location.pathname === '/'} />
          <Nav.Item className="nav-item-separator" />
          <Nav.Item className="nav-item-separator" />
            <SideNavItem
              icon={ProfileIcon}
              text="Profil"
              url="/profile"
              active={location.pathname === '/profile'}
            />
        </Nav>
    );
  }
}

SideNav.propTypes = {
  location: PropTypes.object.isRequired,
};

// <SideNavItem
//   icon={AgreementsIcon}
//   text="Dine aftaler"
//   url="/agreements"
//   active={location.pathname === '/agreements'}
// />
// <SideNavItem
//   icon={ConsumptionIcon}
//   text="Dit forbrug"
//   url="/consumption"
//   active={location.pathname === '/consumption'}
// />
