import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import PrivateLayout from './layouts/PrivateLayout';
import ConsumptionGraphConnector from '../store/connectors/consumption/ConsumptionGraphConnector';
import Api from '../network/api';
import ConsumptionIcon from '../assets/images/icons/consumptionPage/consumptionIcon.png';
import ContactSection from '../components/common/contact/ContactSection';

export default class ConsumptionPage extends Component {
  componentDidMount() {
    const { setUserAgreements, setUserDeliveries } = this.props;
    Api
      .get('/agreements')
      .then(({ data }) => {
        setUserAgreements(data);
      });

    Api
      .get('/deliveries')
      .then(({ data }) => {
        setUserDeliveries(data);
      });
  }

  render() {
    return (
      <PrivateLayout className="page-consumption">
        <Row>
          <Col>
            <header className="page-content-header">
              <Image src={ConsumptionIcon} />
              <span className="page-title">Dit forbrug</span>
            </header>
          </Col>
        </Row>

        <Row>
          <Col>
            <ConsumptionGraphConnector />
          </Col>
        </Row>

        <ContactSection />
      </PrivateLayout>
    );
  }
}

ConsumptionPage.propTypes = {
  setUserAgreements: PropTypes.func.isRequired,
  setUserDeliveries: PropTypes.func.isRequired,
};
