import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/es/Col';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import SideNavConnector from '../../store/connectors/scaffolding/SideNavConnector';
import TopNavBarConnector from '../../store/connectors/scaffolding/TopNavBarConnector';

class PrivateLayout extends React.Component {
  render() {
    const { children, className } = this.props;

    return (
      <Container fluid className={classNames('theme-private', className)}>
        <TopNavBarConnector />
        <Row noGutters>
          <Col xs={12} lg={2}>
            <SideNavConnector />
          </Col>
          <Col xs={12} lg={10} className="page-content">
            {children}
          </Col>
        </Row>
      </Container>
    );
  }
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PrivateLayout.defaultProps = {
  className: '',
};

export default PrivateLayout;
