import { connect } from 'react-redux';
import { setAuthenticatedUser } from '../../actions/authActions';
import UpdateProfileForm from '../../../components/profile/UpdateProfileForm';

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  setAuthenticatedUser: user => dispatch(setAuthenticatedUser(user)),
});

const UpdateProfileFormConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateProfileForm);

export default UpdateProfileFormConnector;
