import React, { useState } from 'react';
import { Modal, Row, Col, Container, Button, Image } from 'react-bootstrap';
import { Provider as StoreProvider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { SelfcareDataStore } from './store/DataStore';
import SelfcareConnector from './store/connectors/SelfcareConnector';
import './assets/sass/app.scss';
import 'react-toastify/scss/main.scss';
import { CookiesProvider, useCookies, Cookies } from 'react-cookie';
import appLogo from './assets/images/brand/appLogo.png';
import { browserName, isMobile, isIOS } from 'react-device-detect';
const moment = require('moment');
function load(name) {
  return require('browser-logos/js-images/' + name);
}

function renderBrwoser(text, options) {
  if (options && typeof options == 'object') {
    const width = options.width || 256;
    const height = options.height || 256;
    const x = options.x || 0;
    const y = options.y || 0;
    return text
      .replace(/^\<svg /, '<svg x="' + x + '" y="' + y + '" ')
      .replace(/^(\<svg [^\>]*width=\")[^\"]+(\")/, '$1' + width + '"')
      .replace(/^(\<svg [^\>]*height=\")[^\"]+(\")/, '$1' + height + '"');
  } else {
    return text;
  }
}

let browserIcon = 'chrome';
let browserText = 'Chrome';
if (browserName == 'Safari') {
  browserIcon = 'apple';
  browserText = 'Safari';
}

const htmlInput = renderBrwoser(load(browserIcon), {
  width: 60,
  height: 60,
  x: 0,
  y: 0,
});

function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-center">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-center">
          Se b.energy som App i stedet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="show-grid">
            <Col xs={8} md={8}>
              <Row>
                <Image src={appLogo} style={{ borderRadius: 15 }} />
                <span
                  style={{ marginLeft: 10, fontWeight: '700', paddingTop: 15 }}
                >
                  b.energy App
                </span>
              </Row>
            </Col>
            <Col xs={4} md={4}>
              <Button
                style={{ borderRadius: 20, marginTop: 10 }}
                href={
                  isIOS
                    ? 'https://apps.apple.com/us/app/blue-energy-app/id1462737600?l=da&ls=1'
                    : 'https://play.google.com/store/apps/details?id=com.blueenergy.selfcare'
                }
                block
                variant="primary"
                style={{ color: '#54b8f3' }}
              >
                Åben
              </Button>
            </Col>
          </Row>

          <Row className="show-grid" style={{ marginTop: 15 }}>
            <Col xs={8} md={8}>
              <Row>
                <div dangerouslySetInnerHTML={{ __html: htmlInput }} />
                <span
                  style={{ marginLeft: 10, fontWeight: '700', paddingTop: 15 }}
                >
                  {browserText}
                </span>
              </Row>
            </Col>
            <Col xs={4} md={4}>
              <Button
                style={{ borderRadius: 20, marginTop: 10 }}
                block
                onClick={props.onHide}
                variant="light"
                style={{ color: '#54b8f3' }}
              >
                Fortsæt
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

const App = () => {
  window.location.replace('https://b.energy/min-side');

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  let modalShowConfig = false;

  if (isMobile && !cookies.doNotShowModalCookie) {
    modalShowConfig = true;
  }

  const [modalShow, setModalShow] = useState(modalShowConfig);

  return (
    <>
      <CookiesProvider>
        <StoreProvider store={SelfcareDataStore}>
          <SelfcareConnector />
        </StoreProvider>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} hideProgressBar />
        <MydModalWithGrid
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setCookie('doNotShowModalCookie', true, {
              path: '/',
              maxAge: 3600,
            });
          }}
        />
      </CookiesProvider>
    </>
  );
};

export default App;
