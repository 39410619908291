import React from 'react';
import PropTypes from 'prop-types';

class OnlinePaymentModal extends React.Component {

  paymentWindow = null;

  constructor(props) {
    super(props);

    this.open = this.open.bind(this);
  }

  getAmountInCents(amount) {

    var amountInCents = amount * 100

    return Math.trunc(amountInCents);
  }

  open() {
    const {
      merchantNumber,
      amount,
      currency,
      orderId,
      acceptUrl,
      callbackUrl,
      isSubscription,
    } = this.props;

    if (!this.paymentWindow) {
      this.paymentWindow = new window.PaymentWindow(
        {
          merchantnumber: merchantNumber,
          amount: this.getAmountInCents(amount),
          currency: currency,
          language: '1',
          orderid: orderId,
          instantcapture: '1',
          ownreceipt: '1',
          accepturl: acceptUrl,
          callbackurl: callbackUrl,
          subscription: isSubscription ? '1' : '0'
        },
      );
    }

    this.paymentWindow.open();
  }

  render() {
    const { children } = this.props
    return (
      <>
        {children}
      </>
    );
  }
}

OnlinePaymentModal.propTypes = {
  merchantNumber: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
  orderId: PropTypes.string,
  acceptUrl: PropTypes.string.isRequired,
  callbackUrl: PropTypes.string.isRequired,
  isSubscription: PropTypes.bool,
}

OnlinePaymentModal.defaultProps = {
  currency: 'DKK',
  isSubscription: false,
}

export default OnlinePaymentModal;
