import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import Api from '../../network/api';

const LoginFormSchema = Yup.object().shape({
  login_id: Yup.string().required('brugernavn er påkrævet'),
  password: Yup.string().required('adgangskode er påkrævet'),
});

class LoginForm extends Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    setAccessToken: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isRememberMeEnabled: false,
    };
  }

  setRememberMeCookie(cookie) {
    const { cookies } = this.props;

    cookies.set(
      cookie.name,
      cookie.value,
      {
        path: '/',
        expires: new Date(cookie.expires),
      },
    );
  }

  handleRememberMeStatusChange(status) {
    this.setState({
      isRememberMeEnabled: status,
    });
  }

  render() {
    const { setAccessToken } = this.props;
    const { isRememberMeEnabled } = this.state;

    return (
      <Formik
        initialValues={{
          login_id: '',
          password: '',
        }}
        validationSchema={LoginFormSchema}
        onSubmit={(values, actions) => {
          Api
            .post('/login', values)
            .then(({ data }) => {
              setAccessToken(data.auth_token.access_token);
              if (isRememberMeEnabled) {
                this.setRememberMeCookie(data.remember_cookie);
              }

              Api
                .get('/user')
                .then(({ data }) => {
                  this.props.setAuthenticatedUser(data);
                  this.props.history.push('/');
                });
            })
            .catch((error) => {
              if (error.response.status === 500) {
                actions.setStatus({ formError: 'Ugyldigt brugernavn eller adgangskode' });
              }
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          status,
        }) => (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                {
                            status
                            && status.formError
                            && <div className="invalid-feedback d-block">{status.formError}</div>
                          }
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12" sm="12" controlId="validationFormikUsername">
                  <Form.Label>Brugernavn</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Indtast kunde- eller telefonnummer."
                      aria-describedby="inputGroup"
                      name="login_id"
                      value={values.login_id}
                      onChange={handleChange}
                      isInvalid={!!errors.login_id && touched.login_id}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.login_id}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12" controlId="validationFormikPassword">
                  <Form.Label>Adgangskode</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="password"
                      placeholder="Indtast adgangskode"
                      aria-describedby="inputGroup"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password && touched.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12" controlId="remember-me-checkbox">
                  <Form.Check
                    custom
                    type="checkbox"
                    id="remember-me-checkbox"
                    label="Husk mig"
                    value={isRememberMeEnabled}
                    onChange={evt => this.handleRememberMeStatusChange(evt.currentTarget.checked)}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row className="justify-content-center">
                <Button type="submit">Log ind</Button>
              </Form.Row>

              <Form.Row className="justify-content-center">
                <Link className="link-generate-password" to="/generate-password">Glemt login?</Link>
              </Form.Row>
            </Form>
          </>
        )}
      </Formik>
    );
  }
}

export default LoginForm;
