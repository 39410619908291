import { connect } from 'react-redux';
import PaidInvoicePage from '../../../pages/PaidInvoicePage';
import { updateInvoice } from '../../actions/invoiceActions';

const mapStateToProps = (state, ownProps) => ({
  invoiceNo: ownProps.match.params.invoiceNo,
  agreementId: ownProps.match.params.agreementId,
});

const mapDispatchToProps = dispatch => ({
  updateInvoice: updatedInvoice => dispatch(
    updateInvoice(updatedInvoice),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaidInvoicePage);
