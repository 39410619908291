import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class DashboardSection extends React.Component {
  render() {
    const {
      headerIcon,
      headerText,
      headerToolbar,
      children,
      className,
    } = this.props;

    return (
      <Card as="section" className={classNames('dashboard-section', className)}>
        <Card.Header as="header" className="dashboard-section-header">
          <Image src={headerIcon} />
          <span className="dashboard-section-title">{headerText}</span>
          <span className="dashboard-section-toolbar">{headerToolbar}</span>
        </Card.Header>
        <Card.Body className="dashboard-section-body">
          {children}
        </Card.Body>
      </Card>
    );
  }
}

DashboardSection.propTypes = {
  headerIcon: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  headerToolbar: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

DashboardSection.defaultProps = {
  className: '',
  headerToolbar: '',
};

export default DashboardSection;
