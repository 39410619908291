export function saveToStorage(authState) {
  try {
    sessionStorage.setItem(
      'auth',
      JSON.stringify(authState)
    )
  } catch (e) {
    console.log(e)
  }
}

export function loadFromStorage() {
  try {
    const serializedState = sessionStorage.getItem('auth')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    console.log(e)
  }
}

export function cleanStorage() {
  try {
    sessionStorage.removeItem('auth')
  } catch (e) {
    console.log(e)
  }
}
