import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ConsumptionIcon from '../../assets/images/icons/consumptionPage/consumptionPanelIcon.png';
import Api from '../../network/api';
import ConsumptionChart from '../common/consumption/ConsumptionChart';
import {
  getConsumptionChartLabelsFromDelivery,
  getEstimatedConsumptionDataFromDelivery,
  getRealConsumptionDataFromDelivery,
} from '../../helpers/consumption';
import ConsumptionSection from './ConsumptionSection';

class ConsumptionGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDelivery: null,
      selectedAgreement: null,
    };

    this.deliverySelector = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const selectedDelivery = prevState.selectedDelivery
      ? nextProps.deliveries.find(item => item.id === prevState.selectedDelivery.id)
      : null;

    return selectedDelivery ? { selectedDelivery } : {};
  }

  onDeliverySelected = () => {
    const { deliveries, setDeliveryConsumption } = this.props;
    const delivery = deliveries.find(
      item => item.id === this.deliverySelector.current.value,
    );

    this.setState({
      selectedAgreement: delivery ? this.getAgreementFromDelivery(delivery) : null,
    });

    if (!delivery) {
      this.setState({ selectedDelivery: null });
    }

    if (delivery) {
      this.setState({ selectedDelivery: delivery }, () => {
        if (!delivery.consumption) {
          Api
            .get(`/consumption/${delivery.id}`)
            .then(({ data }) => {
              setDeliveryConsumption(delivery.id, data);
            });
        }
      });
    }
  }

  getAgreementFromDelivery(delivery) {
    const { agreements } = this.props;

    const result = agreements.find(
      agreement => agreement.deliveries.find(item => item.id === delivery.id),
    );

    return result || null;
  }

  getElectricalDeliveries() {
    const { deliveries } = this.props;
    return deliveries.filter(delivery => delivery.type === 'electrical');
  }

  renderDeliverySelector() {
    const options = [];

    this.getElectricalDeliveries().forEach((delivery) => {
      options.push(
        <option value={delivery.id}>{delivery.address}</option>,
      );
    });

    return (
      <>
        <Form.Control className="custom-select" as="select" ref={this.deliverySelector} onChange={this.onDeliverySelected}>
          <option value="">vælg aftale</option>
          {options}
        </Form.Control>
      </>
    );
  }

  renderAgreementLink(agreement) {
    let agreementSelector;

    if (agreement && agreement.id) {
      agreementSelector = <Link to={`/agreements/${agreement.id}`}>{ agreement.id }</Link>;
    } else {
      agreementSelector = <span>-</span>;
    }

    return (
      <div className="agreement-info">
        <span>Aftalenummer:</span>
        { agreementSelector }
      </div>
    );
  }

  render() {
    const { selectedAgreement, selectedDelivery } = this.state;

    return (
      <ConsumptionSection
        className="consumption-panel"
        headerIcon={ConsumptionIcon}
        headerText="Dit forbrug"
        headerToolbar={this.renderDeliverySelector()}
      >
        { this.renderAgreementLink(selectedAgreement) }
        <ConsumptionChart
          labels={getConsumptionChartLabelsFromDelivery(selectedDelivery)}
          estimatedConsumptionData={getEstimatedConsumptionDataFromDelivery(selectedDelivery)}
          realConsumptionData={getRealConsumptionDataFromDelivery(selectedDelivery)}
          chartLibOptions={{
            scales: {
              xAxes: [{
                categoryPercentage: 0.25,
                barPercentage: 0.75,
                maxBarThickness: 15,
                ticks: {
                  fontSize: 15,
                  padding: 10,
                },
              }],
              yAxes: [{
                ticks: {
                  fontSize: 15,
                  padding: 20,
                },
              }],
            },
          }}
        />
      </ConsumptionSection>
    );
  }
}

ConsumptionGraph.propTypes = {
  deliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
  agreements: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDeliveryConsumption: PropTypes.func.isRequired,
};

export default ConsumptionGraph;
