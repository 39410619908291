import {connect} from "react-redux";
import ProfilePage from "../../../pages/ProfilePage";

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage)
