import { connect } from 'react-redux';
import { updateAgreement } from '../../actions/agreementActions';
import AgreementsAccordionItem from '../../../components/agreements/AgreementsAccordionItem';

const mapStateToProps = (state, ownProps) => ({
  agreement: ownProps.item,
  invoices: state.invoices.filter(invoice => invoice.agreement_id === ownProps.item.id),
});

const mapDispatchToProps = dispatch => ({
  updateAgreement: updatedAgreement => dispatch(updateAgreement(updatedAgreement)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgreementsAccordionItem);
