import {connect} from "react-redux";
import DashboardPage from "../../../pages/DashboardPage";
import {setUserAgreements} from "../../actions/agreementActions";
import {setUserDeliveries} from "../../actions/deliveryActions";
import {setUserInvoices} from "../../actions/invoiceActions";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  setUserAgreements: user => dispatch(setUserAgreements(user)),
  setUserDeliveries: user => dispatch(setUserDeliveries(user)),
  setUserInvoices: user => dispatch(setUserInvoices(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage)
