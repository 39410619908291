import {connect} from "react-redux";
import AgreementPanel from "../../../components/dashboard/AgreementPanel";

const mapStateToProps = state => ({
  agreements: state.agreements
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementPanel)
