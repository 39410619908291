import { connect } from 'react-redux';
import ConsumptionPage from '../../../pages/ConsumptionPage';
import { setUserAgreements } from '../../actions/agreementActions';
import { setUserDeliveries } from '../../actions/deliveryActions';

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  setUserAgreements: user => dispatch(setUserAgreements(user)),
  setUserDeliveries: user => dispatch(setUserDeliveries(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsumptionPage);
