import { connect } from 'react-redux';
import DashboardPaidInvoicePage from '../../../pages/DashboradPaidInvoicePage';
import { updateInvoice } from '../../actions/invoiceActions';

const mapStateToProps = (state, ownProps) => ({
  invoiceNumber: ownProps.match.params.invoiceNumber,
});

const mapDispatchToProps = dispatch => ({
  updateInvoice: updatedInvoice => dispatch(
    updateInvoice(updatedInvoice),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPaidInvoicePage);
