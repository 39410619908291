import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getDeliveryTypeImage } from '../../helpers/deliveries';

class DeliveriesTable extends React.Component {
  render() {
    const { deliveries } = this.props;
    const rows = [];

    deliveries.forEach((delivery) => {
      rows.push(
        <tr key={delivery.id}>
          <td className="delivery-type" align="center">{getDeliveryTypeImage(delivery.type)}</td>
          <td>{this.renderProducts(delivery.products)}</td>
          <td>{this.getProductDescription(delivery)}</td>
          <td>{delivery.address}</td>
        </tr>,
      );
    });

    return (
      <Table size="sm" borderless className="table-deliveries">
        <thead>
          <tr>
            <th>Type</th>
            <th>Produkt</th>
            <th>Produktbeskrivelse</th>
            <th>Leveranceadresse</th>
          </tr>
        </thead>
        <tbody>
          {
            deliveries.length > 0
              ? rows
              : (<tr><td colSpan="4">Der er ingen aktive leverancer på din aftale</td></tr>)
          }
        </tbody>
      </Table>
    );
  }

  renderProducts(products) {
    return (
      <>{products.map((product) => <>{product.name}<br/></>)}</>
    );
  }

  getProductDescription(delivery) {
    return delivery.products && delivery.products[0] ? delivery.products[0].name : '';
  }
}

DeliveriesTable.propTypes = {
  deliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DeliveriesTable
