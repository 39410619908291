import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import PublicLayout from './layouts/PublicLayout';
import LoginFormConnector from '../store/connectors/auth/LoginFormConnector';
import Api from '../network/api';

class LoginPage extends Component {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setAuthenticatedUser: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {
      history, cookies, user, setAuthenticatedUser,
    } = this.props;

    if (user === null && Object.keys(cookies.cookies).filter(cookie => cookie.startsWith('remember_')).length) {
      Api
        .get('/user')
        .then(
          ({ data }) => {
            setAuthenticatedUser(data);
            history.push('/');
          },
        );
    }
  }

  render() {
    return (
      <PublicLayout>
        <div className="page-login-content">
          <Row className="justify-content-center">
            <header>Log ind på Min Side</header>
          </Row>
          <Row className="justify-content-center">
            <LoginFormConnector />
          </Row>
        </div>
      </PublicLayout>
    );
  }
}

export default LoginPage;
