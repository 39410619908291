import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class AgreementsSection extends React.Component {
  render() {
    const {
      headerText,
      children,
      className,
    } = this.props;

    return (
      <section className={classNames('agreements-section', className)}>
        <header>{headerText}</header>
        {children}
      </section>
    );
  }
}

AgreementsSection.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

AgreementsSection.defaultProps = {
  className: '',
};

export default AgreementsSection;
