import React from 'react';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { Col, Form, Row } from 'react-bootstrap';
import UpdateProfileFormConnector from '../../store/connectors/profile/UpdateProfileFormConnector';
import UserType from '../../types/UserType';

class ProfileSummary extends React.Component {
  getContactName() {
    const { user } = this.props;

    if (user.type === UserType.business) {
      return `${user.first_name} ${user.last_name}`;
    }

    return user.name;
  }

  getCompanyName() {
    const { user } = this.props;

    return (user.type === UserType.business) ? user.name : '';
  }

  getCompanyCvr() {
    const { user } = this.props;

    return (user.type === UserType.business) ? user.cvr : '';
  }

  convertCustomer_no(n, width, z) {
    z = z || '0';
    n = n + '';
      return n.length >= width ? "B" + n : "B" + new Array(width - n.length + 1).join(z) + n;
  }

  render() {
    const { user } = this.props;

    return (
      <Row>
        <Col>
          <Card className="profile-summary">
            <Card.Body as={Row} className="profile-non-editable-data">
              <Col xs={12} lg={6} className="profile-left-column">
                <Form.Group as={Row} controlId="profile-customer-number">
                  <Form.Label column xs="4">
                  Kundenummer
                  </Form.Label>
                  <Col xs="8">
                    <Form.Control plaintext readOnly defaultValue={this.convertCustomer_no(user.customer_no, 7)} />
                  </Col>
                </Form.Group>

                { user.type == 'business' ?
                  <div>
                   <Form.Group as={Row} controlId="profile-company-name">
                                  <Form.Label column xs="4">
                                  Firmanavn
                                  </Form.Label>
                                  <Col xs="8">
                                    <Form.Control plaintext readOnly defaultValue={this.getCompanyName()} />
                                  </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="profile-company-cvr">
                                  <Form.Label column xs="4">
                                  CVR nr.
                                  </Form.Label>
                                  <Col xs="8">
                                    <Form.Control plaintext readOnly defaultValue={this.getCompanyCvr()} />
                                  </Col>
                    </Form.Group>
                   </div>
                                : null
                              }



              </Col>

              <Col xs={12} lg={6} className="profile-right-column">
                <Form.Group as={Row} controlId="profile-customer-name">
                  <Form.Label column xs="4">
                  Navn
                  </Form.Label>
                  <Col xs="8">
                    <Form.Control plaintext readOnly defaultValue={this.getContactName()} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="profile-customer-address">
                  <Form.Label column xs="4">
                  Addresse
                  </Form.Label>
                  <Col xs="8">
                    <Form.Control plaintext readOnly defaultValue={user.address} />
                  </Col>
                </Form.Group>
              </Col>
            </Card.Body>


          </Card>
        </Col>
      </Row>
    );
  }
}

ProfileSummary.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProfileSummary;


// <UpdateProfileFormConnector
//   email={user.email}
//   phone={user.phone_number}
//   alternativePhone={user.alternative_phone_number}
// />
