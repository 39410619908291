import frmtMoney from 'accounting-js/lib/formatMoney';
import { DateTime } from 'luxon';

export function formatMoney(amount, symbol = '', precision = 2) {
  return frmtMoney(amount, {
    symbol, precision, thousand: '.', decimal: ',',
  });
}

export function formatIsoDate(date) {
  return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
}
