import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import PrivateLayout from './layouts/PrivateLayout';
import ProfileSummary from '../components/profile/ProfileSummary';
import ProfileIcon from '../assets/images/icons/profilePage/profileIcon.png';
import ContactSection from '../components/common/contact/ContactSection';

class ProfilePage extends Component {
  render() {
    const { user } = this.props;

    return (
      <PrivateLayout className="page-profile">
        <Row>
          <Col>
            <header className="page-content-header">
              <Image src={ProfileIcon} />
              <span className="page-title">Profil</span>
            </header>
          </Col>
        </Row>

        <ProfileSummary user={user} />
        <ContactSection />

      </PrivateLayout>
    );
  }
}

ProfilePage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProfilePage;
