import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../assets/images/brand/b-energy-logo-neg.png';

class PublicTopNavBar extends React.Component {
  render() {
    return (
      <Navbar>
        <Navbar.Brand className="mx-auto">
        <a href="https://www.b.energy/kundeservice">
          <img
            height="70"
            src={logo}
            alt="b.energy logo"
          />
          </a>
        </Navbar.Brand>
      </Navbar>
    );
  }
}

export default PublicTopNavBar;
