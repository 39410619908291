import { ActionTypes} from "../Types";

export const setUserDeliveries = deliveries => ({
  type: ActionTypes.SET_USER_DELIVERIES,
  deliveries: deliveries
})

export const setDeliveryConsumption = (deliveryId, consumption) => ({
  type: ActionTypes.SET_DELIVERY_CONSUMPTION,
  deliveryId: deliveryId,
  consumption: consumption
})
