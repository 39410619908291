import { combineReducers } from 'redux';
import agreements from './agreements';
import auth from './auth';
import deliveries from './deliveries';
import invoices from './invoices';

export default combineReducers({
  auth,
  agreements,
  deliveries,
  invoices,
});
