import React from 'react';
import { Image } from 'react-bootstrap';
import ElectricalDeliveryIcon from '../assets/images/icons/dashboardPage/electricalDeliveryIcon.png';
import SubscriptionDeliveryIcon from '../assets/images/icons/dashboardPage/subscriptionDeliveryIcon.png';

export function getDeliveryTypeImage(deliveryType) {
  switch (deliveryType) {
    case 'electrical':
      return <Image src={ElectricalDeliveryIcon} />;
    case 'subscription':
    default:
      return <Image src={SubscriptionDeliveryIcon} />;
  }
}