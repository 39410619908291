import React from 'react';
import { Image, Button, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import AgreementsIcon from '../../assets/images/icons/dashboardPage/agreementPanelIcon.png';
import DashboardSection from './DashboardSection';
import RightArrowIcon from '../../assets/images/icons/dashboardPage/rightArrowIcon.png';
import { getDeliveryTypeImage } from '../../helpers/deliveries';

class AgreementPanel extends React.Component {
  renderProducts(products) {
    return (
      <>
        {products.map(product => (
          <>
            {product.name}
            <br />
          </>
        ))}
      </>
    );
  }

  convertAgreement_no(n, width, z) {
    z = z || '0';
    n = n + '';
      return n.length >= width ? "A" + n : "A" + new Array(width - n.length + 1).join(z) + n;
  }

  render() {
    const rows = [];
    const { agreements } = this.props;

    agreements.forEach((agreement) => {
      agreement.deliveries.forEach((delivery) => {
        if (delivery.end_date === null || DateTime.fromISO(delivery.end_date) > DateTime.local()) {
          rows.push(
            <tr key={delivery.id}>
              <td className="delivery-type" align="center">{getDeliveryTypeImage(delivery.type)}</td>
              <td><NavLink to={`/agreements/${agreement.id}`}>{this.convertAgreement_no(agreement.id, 8)}</NavLink></td>
              <td>{agreement.alias}</td>
              <td>{this.renderProducts(delivery.products)}</td>
              <td>{delivery.address}</td>
              <td className="action-buttons">
                <Button className="btn-open-agreement" href={`/agreements/${agreement.id}`}>
                  <Image src={RightArrowIcon}/>
                  Se aftale
                </Button>
              </td>
            </tr>,
          );
        }
      });
    });

    return (
      <DashboardSection
        className="agreements-panel"
        headerIcon={AgreementsIcon}
        headerText="Dine aftaler"
      >
        <Table striped borderless responsive={true}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Aftalenr.</th>
              <th>Alias</th>
              <th>Produkt</th>
              <th>Adresse</th>
              <th>Aftaleinformation</th>
            </tr>
          </thead>
          <tbody>
            {
              rows.length > 0
                ? rows
                : (<tr><td colSpan="6">Der er ingen aktive leverancer på din aftale</td></tr>)
            }
          </tbody>
        </Table>
      </DashboardSection>
    );
  }
}

AgreementPanel.propTypes = {
  agreements: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AgreementPanel;
