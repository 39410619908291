import { ActionTypes } from '../Types';

export const setUserAgreements = agreements => ({
  type: ActionTypes.SET_USER_AGREEMENTS,
  agreements: agreements,
})

export const updateAgreement = updatedAgreement => ({
  type: ActionTypes.UPDATE_AGREEMENT,
  updatedAgreement: updatedAgreement,
})
