import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import UserInfoConnector from '../../store/connectors/scaffolding/UserInfoConnector';
import logo from '../../assets/images/brand/b-energy-logo-neg.png';
import logOutIcon from '../../assets/images/icons/topNavBar/exit.png';
import Api from '../../network/api';

class TopNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.logOutUser = this.logOutUser.bind(this);
  }

  logOutUser() {
    const {
      history,
      cookies,
      clearAuthenticatedUser,
      clearAccessToken,
    } = this.props;

    Api
      .post('/logout', {})
      .then(() => {
        clearAuthenticatedUser();
        clearAccessToken();
        Object.keys(cookies.cookies).filter(cookieName => cookieName.startsWith('remember_'))
          .forEach(cookieName => cookies.remove(cookieName));
        history.push('/login');
      });
  }

  render() {
    return (
      <Navbar>
        <UserInfoConnector />
        <Navbar.Brand className="mx-auto">
        <a href="https://www.b.energy/kundeservice">
          <img
            height="70"
            src={logo}
            alt="b.energy logo"
          />
          </a>
        </Navbar.Brand>
        <Navbar.Text className="navbar-item-logout">
          <Button variant="link" style={{color: '#54b8f3'}} onClick={this.logOutUser}>
            <Image src={logOutIcon} />
            <span className="d-none d-lg-flex">Log ud</span>
          </Button>
        </Navbar.Text>
      </Navbar>
    );
  }
}

export default TopNavBar;
