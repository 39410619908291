import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import Api from '../../network/api';
import ProfileType from '../../types/DeliveryType';
import ConsumptionChart from '../common/consumption/ConsumptionChart';
import {
  getConsumptionChartLabelsFromDelivery,
  getEstimatedConsumptionDataFromDelivery,
  getRealConsumptionDataFromDelivery,
} from '../../helpers/consumption';
import ConsumptionIcon from '../../assets/images/icons/dashboardPage/consumptionPanelIcon.png';
import DashboardSection from './DashboardSection';

class ConsumptionPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDelivery: null,
    };

    this.deliverySelector = React.createRef();

    this.onDeliverySelected = this.onDeliverySelected.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const selectedDelivery = prevState.selectedDelivery
      ? nextProps.deliveries.find(item => item.id === prevState.selectedDelivery.id)
      : null;

    return selectedDelivery ? { selectedDelivery } : {};
  }

  onDeliverySelected() {
    const deliveryId = this.deliverySelector.current.value;
    const { deliveries, setDeliveryConsumption } = this.props;
    const delivery = deliveries.find(item => item.id === deliveryId);

    if (!delivery) {
      this.setState({ selectedDelivery: null });
    }

    if (delivery && delivery.consumption) {
      this.setState({ selectedDelivery: delivery });
    }

    if (delivery) {
      this.setState({ selectedDelivery: delivery }, () => {
        if (!delivery.consumption) {
          Api
            .get(`/consumption/${delivery.id}`)
            .then(({ data }) => {
              setDeliveryConsumption(delivery.id, data);
            });
        }
      });
    }
  }

  renderDeliverySelector() {
    const options = [];
    const { deliveries } = this.props;

    deliveries.forEach((delivery) => {
      if (
        (delivery.type === ProfileType.electrical)
        && (delivery.end_date === null || DateTime.fromISO(delivery.end_date) > DateTime.local())
      ) {
        options.push(
          <option value={delivery.id}>{delivery.address}</option>,
        );
      }
    });

    return (
      <>
        <Form.Control className="custom-select" as="select" ref={this.deliverySelector} onChange={this.onDeliverySelected}>
          <option value="">vælg aftale</option>
          {options}
        </Form.Control>
      </>
    );
  }

  render() {
    const { selectedDelivery } = this.state;
    return (
      <DashboardSection
        className="consumption-panel"
        headerIcon={ConsumptionIcon}
        headerText="Dit forbrug"
        headerToolbar={this.renderDeliverySelector()}
      >
        <ConsumptionChart
          labels={getConsumptionChartLabelsFromDelivery(selectedDelivery)}
          estimatedConsumptionData={getEstimatedConsumptionDataFromDelivery(selectedDelivery)}
          realConsumptionData={getRealConsumptionDataFromDelivery(selectedDelivery)}
          chartLibOptions={{
            scales: {
              xAxes: [{
                categoryPercentage: 0.5,
                barPercentage: 0.75,
                maxBarThickness: 11,
                ticks: {
                  fontSize: 11,
                  padding: 10,
                },
              }],
              yAxes: [{
                ticks: {
                  fontSize: 11,
                  padding: 20,
                },
              }],
            },
          }}
        />
      </DashboardSection>
    );
  }
}

ConsumptionPanel.propTypes = {
  setDeliveryConsumption: PropTypes.func.isRequired,
  deliveries: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ConsumptionPanel;
