export function getStatusDanishTranslation(status) {
  switch (status) {
    case 'collected':
      return 'Opkrævet';
    case 'credited':
      return 'Krediteret';
    case 'unpaid':
      return 'Ubetalt';
    case 'paid':
      return 'Betalt';
    case 'unknown':
    default:
      return 'Ukendt';
  }
}
