import { ActionTypes } from '../Types';

const initialState = [
]

const agreements = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_AGREEMENTS:
      return action.agreements;

    case ActionTypes.UPDATE_AGREEMENT:
      return state.map((agreement) => {
        if (agreement.number === action.updatedAgreement.number) {
          return action.updatedAgreement;
        }

        return agreement;
      });

    default:
      return state;
  }
}

export default agreements
