import React, { Component } from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Api from '../../network/api';

const UpdateProfileFormSchema = Yup.object().shape({
  email: Yup.string(),
  phone: Yup.string(),
  alternative_phone: Yup.string(),
});

class UpdateProfileForm extends Component {
  render() {
    const { email, phone, alternativePhone } = this.props;
    const { setAuthenticatedUser } = this.props;

    return (
      <Formik
        initialValues={{
          email,
          phone,
          alternative_phone: alternativePhone,
        }}
        validationSchema={UpdateProfileFormSchema}
        onSubmit={(values) => {
          Api
            .patch('/users', values)
            .then(
              ({ data }) => {
                setAuthenticatedUser(data);
                toast.success('Din profile er blevet opdateret');
              },
              () => {
                toast.error('Fejl ved opdatering af din profil. Kontakt kundeservice');
              },
            );
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Card.Body as={Row} className="profile-editable-data">
              <Col xs={12} lg={6} className="profile-left-column">
                <Form.Group as={Row} className="profile-phone">
                  <Form.Label column xs="4">Telefonnummer</Form.Label>
                  <Col xs="8">
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder=""
                        aria-describedby="inputGroup"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        isInvalid={!!errors.phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="profile-email">
                  <Form.Label column xs="4">E-mail</Form.Label>
                  <Col xs="8">
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder=""
                        aria-describedby="inputGroup"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Col xs={12} lg={6} controlId="profile-editable-right-column">
                <Form.Group as={Row} className="profile-mobile">
                  <Form.Label column xs="4">Mobil</Form.Label>
                  <Col xs="8">
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder=""
                        aria-describedby="inputGroup"
                        name="alternative_phone"
                        value={values.alternative_phone}
                        onChange={handleChange}
                        isInvalid={!!errors.alternative_phone}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.alternative_phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Col>

              <Button type="submit">Opdatér oplysninger</Button>
            </Card.Body>
          </Form>
        )}
      </Formik>
    );
  }
}

UpdateProfileForm.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  alternativePhone: PropTypes.string,
  setAuthenticatedUser: PropTypes.func,
};

UpdateProfileForm.defaultProps = {
  email: '',
  phone: '',
  alternativePhone: '',
  setAuthenticatedUser: () => {},
};

export default UpdateProfileForm;
