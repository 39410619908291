import { connect } from 'react-redux';
import { setUserAgreements } from '../../actions/agreementActions';
import { setUserInvoices } from '../../actions/invoiceActions';
import AgreementPage from '../../../pages/AgreementPage';

const mapStateToProps = (state, ownProps) => ({
  agreements: state.agreements,
  selectedAgreement: state.agreements.find(
    agreement => agreement.id && agreement.id.toString() === ownProps.match.params.agreementId,
  ),
});

const mapDispatchToProps = dispatch => ({
  setUserAgreements: user => dispatch(setUserAgreements(user)),
  setUserInvoices: user => dispatch(setUserInvoices(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgreementPage);
