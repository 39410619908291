import {connect} from "react-redux";
import ConsumptionPanel from "../../../components/dashboard/ConsumptionPanel";
import {setDeliveryConsumption} from "../../actions/deliveryActions";

const mapStateToProps = state => ({
  deliveries: state.deliveries
})

const mapDispatchToProps = dispatch => ({
  setDeliveryConsumption: (deliveryId, consumption) => dispatch(setDeliveryConsumption(deliveryId, consumption)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumptionPanel)
