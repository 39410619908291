import React, {Component} from "react";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Image from 'react-bootstrap/Image';
import FirstPageIcon from '../../assets/images/icons/paginator/firstPageIcon.png';
import LastPageIcon from '../../assets/images/icons/paginator/lastPageIcon.png';
import NextPageIcon from '../../assets/images/icons/paginator/nextPageIcon.png';
import PreviousPageIcon from '../../assets/images/icons/paginator/previousPageIcon.png';

class Paginator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      pageLimit: 0,
      totalRecords: 0,
      totalPages: 0
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const pageLimit = typeof nextProps.pageLimit === "number" ? nextProps.pageLimit : 30;
    const totalRecords = typeof nextProps.totalRecords === "number" ? nextProps.totalRecords : 0;

    return {
      currentPage: Math.max(1, nextProps.currentPage),
      pageLimit: Math.max(0, pageLimit),
      totalRecords: Math.max(0, totalRecords),
      totalPages: Math.ceil(totalRecords / pageLimit)
    }
  }

  componentDidMount() {
    this.gotoPage(this.state.currentPage);
  }

  gotoPage = page => {
    const { onPageChanged = f => f } = this.props;

    const newState = {
      ...this.state,
      currentPage: Math.max(1, Math.min(page, this.state.totalPages))
    };

    this.setState(newState, () => onPageChanged(newState));
  };

  handleMoveFirst = evt => {
    evt.preventDefault();
    this.gotoPage(1);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - 1);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + 1);
  };

  handleMoveLast = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.totalPages);
  };

  render() {
    const { currentPage, totalPages, totalRecords } = this.state;

    if (!totalRecords) return null;
    if (totalPages === 1) return null;

    return (
        <ButtonToolbar bsPrefix="paginator" aria-label="Paginator">
          <Button onClick={this.handleMoveFirst} disabled={currentPage === 1}>
            <Image src={FirstPageIcon} />
          </Button>
          <Button onClick={this.handleMoveLeft} disabled={currentPage === 1}>
            <Image src={PreviousPageIcon} />
          </Button>
          <span className="pageIndicator">
            {currentPage} af {totalPages} sider
          </span>
          <Button onClick={this.handleMoveRight} disabled={currentPage === totalPages}>
            <Image src={NextPageIcon} />
          </Button>
          <Button onClick={this.handleMoveLast} disabled={currentPage === totalPages}>
            <Image src={LastPageIcon} />
          </Button>
        </ButtonToolbar>
    );
  }
}

Paginator.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  onPageChanged: PropTypes.func
};

Paginator.defaultProps = {
  currentPage: 1
}

export default Paginator;
