import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ContactAd from './ContactAd';
import PowerCutIcon from '../../../assets/images/icons/profilePage/socketIcon.png';
import ContactIcon from '../../../assets/images/icons/profilePage/contactIcon.png';

class ContactSection extends React.Component {
  render() {
    return (
      <Row noGutters className="section-contact">
        <Col xs={12} lg={6}>
          <ContactAd
            className="power-cut-ad"
            icon={PowerCutIcon}
            title="Oplever du strømafbrydelser?"
            phone="Tlf. 70 28 40 00"
            description="Oplever du strømnedbrud på adressen, kan du kontakte os på ovenstående telefonnummer."
          />
        </Col>
        <Col xs={12} lg={6}>
          <ContactAd
            className="contact-ad"
            icon={ContactIcon}
            title="Brug for hjælp?"
            phone="Tlf. 70 28 40 00"
            description={'Vores kundeservice er åben mandag til fredag:\n09.00 og 15.00. Weekend og helligdage holder vi lukket.'}
          />
        </Col>
      </Row>
    );
  }
}

export default ContactSection;
