import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Api from '../../network/api';

const GeneratePasswordFormSchema = Yup.object().shape({
  phone: Yup.string().required('telefonnummer er påkrævet'),
});

class GeneratePasswordForm extends Component {
  render() {
    const { history } = this.props;

    return (
      <Formik
        initialValues={{
          phone: '',
        }}
        validationSchema={GeneratePasswordFormSchema}
        onSubmit={(values) => {
          Api
            .post('/generate-password', values)
            .then(() => {
              history.push('/login');
              toast.success('En ny adgangskode er sendt til din telefon');
            })
            .catch((error) => {
              if (error.response.status === 500) {
                toast.error('Mislykket adgangskode ændringsanmodning');
              }
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row className="label-explanation">
              Hvis du indtaster dit telefonnummer herunder får du tilsendt et password som sms
              <div>
              </div>
            </Form.Row>
            <Form.Row>
              <Form.Control
                type="text"
                placeholder="Indtast telefonnummner."
                aria-describedby="inputGroup"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                isInvalid={!!errors.phone && touched.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Row>
            <Form.Row className="actions-panel">
              <Button type="submit">Send kodeord som SMS</Button>
              <Link to="/login">Gå til login</Link>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
  }
}

GeneratePasswordForm.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(GeneratePasswordForm);
