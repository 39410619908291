import { ActionTypes } from '../Types';

const initialState = [
]

const invoices = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_INVOICES:
      return action.invoices
    case ActionTypes.UPDATE_INVOICE:
      return state.map((invoice) => {
        if (invoice.invoice_no === action.updatedInvoice.invoice_no) {
          return action.updatedInvoice;
        }
        return invoice;
      });
    default:
      return state;
  }
}

export default invoices;
