import Api from "../network/api";
import React, {Component} from "react";
import PrivateLayout from './layouts/PrivateLayout'
import AgreementsAccordionConnector from "../store/connectors/agreements/AgreementsAccordionConnector";
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import AgreementsIcon from '../assets/images/icons/agreementsPage/agreementsIcon.png';
import Switch from "react-switch";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import FoldedIcon from '../assets/images/icons/agreementsPage/agreementFolded.png';
import AgreementIcon from '../assets/images/icons/agreementsPage/agreementIcon.png';


class AgreementPage extends Component {

  constructor(props){
        super(props);
        this.state={
        //  hideInactiveAgreements: false,
          agreements: [],
          done: false
        };
    //    this.handleChange = this.handleChange.bind(this);
    }

//     handleChange(hideInactiveAgreements) {
//     this.setState({ hideInactiveAgreements });
//   }
//
//   checkForEndDate(item) {
//       var end_dates = item.deliveries.map(element => element.end_date);
//       if (end_dates.every(element => element == null)) {
//           return item;
//       }
// }

    componentDidMount() {
            Api
            .get('/agreements')
            .then(({ data }) => {
                        this.props.setUserAgreements(data)
                        this.setState({ agreements: data });
                        this.setState({ done: true });
            });

      // Api
      //   .get('/agreements')
      //   .then(({ data }) => {
      //     //render all agreements
      //       if (!this.state.hideInactiveAgreements) {
      //           this.props.setUserAgreements(data)
      //           this.setState({ agreements: data });
      //       } else {
      //         //render active agreements
      //         data = data.filter(this.checkForEndDate)
      //         this.props.setUserAgreements(data)
      //         this.setState({ agreements: data });
      //       }
      //   });

      Api
        .get('/invoices')
        .then(({ data }) => {
            this.props.setUserInvoices(data)
        })

    };





  render() {
    const { selectedAgreement, item, invoices, folded } = this.props;
    const divStyle = {
      marginLeft: '200px'
    };
    const cardStyle = {
      marginTop: '20px'
    };


    return (
      <PrivateLayout className="page-agreements">
        <Row>
          <Col>
            <header className="page-content-header">
              <Image src={AgreementsIcon} />
              <span className="page-title">Dine aftaler</span>
            </header>
          </Col>
        </Row>
        <Row>
          <Col>
            {!this.state.done ?
              <div>
                  <Card style={cardStyle} className={classNames('agreements-accordion-item', { folded })}>
                  <Card.Header className="agreement-item-header">
                    <Image src={AgreementIcon} />
                  </Card.Header>
                </Card>
                <Card style={cardStyle} className={classNames('agreements-accordion-item', { folded })}>
                <Card.Header className="agreement-item-header">
                  <Image src={AgreementIcon} />
                </Card.Header>
              </Card>
              <Card style={cardStyle} className={classNames('agreements-accordion-item', { folded })}>
              <Card.Header className="agreement-item-header">
                <Image src={AgreementIcon} />
              </Card.Header>
            </Card>
            <Card style={cardStyle} className={classNames('agreements-accordion-item', { folded })}>
            <Card.Header className="agreement-item-header">
              <Image src={AgreementIcon} />
            </Card.Header>
          </Card>
          <Card style={cardStyle} className={classNames('agreements-accordion-item', { folded })}>
          <Card.Header className="agreement-item-header">
            <Image src={AgreementIcon} />
          </Card.Header>
        </Card>
        <Card style={cardStyle} className={classNames('agreements-accordion-item', { folded })}>
        <Card.Header className="agreement-item-header">
          <Image src={AgreementIcon} />
        </Card.Header>
      </Card>
          </div>
: <AgreementsAccordionConnector selectedAgreement={selectedAgreement} />}
          </Col>
        </Row>
      </PrivateLayout>
    );
  }
}

AgreementPage.propTypes = {
  selectedAgreement: PropTypes.object,
};

export default AgreementPage

// <div style={divStyle}>
//    <Switch onChange={this.handleChange} checked={this.state.hideInactiveAgreements} />
// </div>
