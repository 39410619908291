import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import { Form, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import DeliveriesTable from './DeliveriesTable';
import InvoicesTable from '../invoices/InvoicesTable';
import SubscriptionSummaryConnector from '../../store/connectors/agreements/SubscriptionSummaryConnector';
import Api from '../../network/api';
import EditAliasIcon from '../../assets/images/icons/agreementsPage/pencilWhite.png';
import AgreementIcon from '../../assets/images/icons/agreementsPage/agreementIcon.png';
import FoldedIcon from '../../assets/images/icons/agreementsPage/agreementFolded.png';
import UnfoldedIcon from '../../assets/images/icons/agreementsPage/agreementUnfolded.png';
import AgreementsSection from './AgreementsSection';

class AgreementsAccordionItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditingAlias: false,
    };

    this.agreementAliasInput = React.createRef();
  }

  toggleAliasEditMode = () => {
    this.setState(prevState => ({
      isEditingAlias: !prevState.isEditingAlias,
    }));
  }

  handleAliasUpdate = (target) => {
    const { item, updateAgreement } = this.props;

    if (target.charCode === 13) {
      Api
        .patch(`/agreements/${item.number}/alias`, { alias: this.agreementAliasInput.current.value })
        .then(({ data }) => {
          updateAgreement(data);
        })
        .catch(() => {
          toast.error('Der opstod en fejl ændre alias');
        })
        .finally(() => {
          this.setState(() => ({
            isEditingAlias: false,
          }));
        });
    }
  }

  renderAlias() {
    const { isEditingAlias } = this.state;
    const { item } = this.props;

    const aliasTag = isEditingAlias
      ? (
        <Form.Control
          type="text"
          ref={this.agreementAliasInput}
          defaultValue={item.alias ? item.alias : ''}
          onKeyPress={this.handleAliasUpdate}
        />
      )
      : (
        <span>
          { item.alias ? item.alias : '-' }
        </span>
      );

    return (
      <>
        {aliasTag}
        <Image src={EditAliasIcon} onClick={this.toggleAliasEditMode} />
      </>
    );
  }

  convertAgreement_no(n, width, z) {
    z = z || '0';
    n = n + '';
      return n.length >= width ? "A" + n : "A" + new Array(width - n.length + 1).join(z) + n;
  }

  render() {
    const { item, invoices, folded } = this.props;

    const filteredDeliveries = item.deliveries.filter(
      delivery => delivery.end_date === null
        || DateTime.fromISO(delivery.end_date) > DateTime.local(),
    )

    return (
      <Card className={classNames('agreements-accordion-item', { folded })}>
        <Card.Header className="agreement-item-header">
          <Image src={AgreementIcon} />
          <span className="agreement-number">{this.convertAgreement_no(item.id, 8)}</span>
          <span className="agreement-alias">{this.renderAlias()}</span>
          <Accordion.Toggle as="div" eventKey={item.id} className="agreements-accordion-item-toggle">
            <Image src={folded ? FoldedIcon : UnfoldedIcon} />
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={item.id}>
          <Card.Body className="agreement-item-body">
            <SubscriptionSummaryConnector agreement={item} />
            <AgreementsSection headerText="Indhold af aftale" className="agreement-deliveries-section">
              <DeliveriesTable deliveries={filteredDeliveries}
              />
            </AgreementsSection>
            <AgreementsSection headerText="Regninger på aftale" className="agreement-invoices-section">
              <InvoicesTable invoices={invoices} agreementId={item.id} />
            </AgreementsSection>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }
}

AgreementsAccordionItem.propTypes = {
  item: PropTypes.object.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateAgreement: PropTypes.func.isRequired,
  folded: PropTypes.bool,
};

AgreementsAccordionItem.defaultProps = {
  folded: true,
};

export default AgreementsAccordionItem;
