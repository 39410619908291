import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import GeneratePasswordForm from '../../../components/login/GeneratePasswordForm';

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneratePasswordForm));
