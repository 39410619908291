import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withCookies } from 'react-cookie';
import { setAccessToken, setAuthenticatedUser } from '../../actions/authActions';
import LoginForm from '../../../components/login/LoginForm';

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  setAuthenticatedUser: user => dispatch(setAuthenticatedUser(user)),
  setAccessToken: token => dispatch(setAccessToken(token)),
});

const LoginFormConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);

export default withCookies(withRouter(LoginFormConnector));
